<template>
  <wx-autocomplete
    v-model="selectedProductionUnits"
    :items="sortedAvailableProductionUnits"
    item-text="name"
    item-value="id"
    :label="$t('multiPuSelector.label')"
    :rules="[() => validateProductionUnits()]"
    :clearable="clearable"
    multiple
    return-object
    @change="emitSelection"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleProductionUnitSelection" :dense="true">
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ $t("common.all") }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" :disabled="item.disabled" :dense="true">
        <v-list-item-icon v-bind="attrs">
          <v-icon v-if="attrs.inputValue">mdi-checkbox-marked</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="isMultipleFactories">{{ `${item.factory} / ${item.name}` }}</span>
            <span v-else>{{ item.name }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ index, item }">
      <div v-if="allProductionUnitsSelected(index)" class="mt-2">
        <span>{{ $t("common.all") }}</span>
      </div>
      <div v-if="isLessThanMaxChipCount()">
        <v-chip
          v-if="index < maxNumberOfChips"
          label
          small
          :close="!isProductionUnitDisabled(item.id)"
          color="primary"
          class="my-1"
          @click:close="removeByIndex(index)"
        >
          <span> {{ selectedProductionUnits[index].name }} </span>
        </v-chip>
        <v-chip
          v-else-if="index === maxNumberOfChips"
          label
          small
          close
          color="primary"
          @click:close="removeByIndex(index)"
        >
          <span>{{ $t("common.numberOfChipsRemaining", [selectedProductionUnits.length - maxNumberOfChips]) }}</span>
        </v-chip>
      </div>
    </template>
  </wx-autocomplete>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import { compareProductionUnitsByName, sortProductionUnitsByDisabledStatus } from "@/components/SortUtils";

export default {
  name: "WxProductionUnitMultiSelector",
  components: {
    WxAutocomplete,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isMultipleFactories: {
      type: Boolean,
      required: true,
    },
    availableProductionUnits: {
      type: Array,
      required: true,
    },
    maxNumberOfChips: {
      type: Number,
      default: () => 6,
    },
    acceptEmptySelection: {
      type: Boolean,
      default: () => false,
    },
    disabledProductionUnits: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedProductionUnits: this.value,
    };
  },
  watch: {
    value() {
      this.selectedProductionUnits = this.value;
    },
    selectedProductionUnits() {
      this.emitSelection();
    },
  },
  computed: {
    icon() {
      if (
        this.selectedProductionUnits &&
        this.selectedProductionUnits.length === this.availableProductionUnits.length
      ) {
        return "mdi-close-box";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },
    sortedAvailableProductionUnits() {
      if (this.availableProductionUnits && this.availableProductionUnits.length > 0) {
        let puList = [...this.availableProductionUnits];
        // sort first by name
        puList.sort(compareProductionUnitsByName);
        // set the disabled status
        puList.map((pu) => (pu.disabled = this.isProductionUnitDisabled(pu.id)));
        // sort by disabled status
        puList.sort(sortProductionUnitsByDisabledStatus);
        return puList;
      }
      return [];
    },
  },
  methods: {
    validateProductionUnits() {
      if (!this.selectedProductionUnits || this.selectedProductionUnits.length === 0) {
        if (!this.acceptEmptySelection) {
          return this.$t("multiPuSelector.errors.noProductionUnits");
        }
      }
      return true;
    },
    toggleProductionUnitSelection() {
      this.$nextTick(() => {
        if (this.selectedProductionUnits.length === this.availableProductionUnits.length) {
          this.selectedProductionUnits = [...this.disabledProductionUnits];
        } else {
          this.selectedProductionUnits = [...this.availableProductionUnits];
        }
      });
    },
    isProductionUnitDisabled(puId) {
      return this.disabledProductionUnits.findIndex((pu) => pu.id === puId) >= 0;
    },
    allProductionUnitsSelected(index) {
      return index === 0 && this.selectedProductionUnits.length === this.availableProductionUnits.length;
    },
    isLessThanMaxChipCount() {
      return this.selectedProductionUnits.length < this.availableProductionUnits.length;
    },
    removeByIndex(index) {
      let toRemove = this.selectedProductionUnits[index];
      if (toRemove) {
        let newList = [];
        this.selectedProductionUnits.forEach((pu) => {
          if (pu !== toRemove) {
            newList.push(pu);
          }
        });
        this.selectedProductionUnits = newList;
      }
    },
    emitSelection() {
      this.$emit("input", this.selectedProductionUnits);
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.pu-as-chips {
  min-height: 40px;
}
</style>
